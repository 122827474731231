import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/')({
  component: RouteComponent,
  beforeLoad: (ctx) => {
    throw redirect({ to: '/$pathLocale', params: { pathLocale: ctx.context.i18n.language } });
  },
});

function RouteComponent() {
  return null;
}
