import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { FALLBACK_LOCALE, SUPPORTED_LOCALES } from './supported-locales.ts';

i18n
  .use(
    resourcesToBackend((language: string, namespace: string) => {
      return import(`./translations/${language}/${namespace}.json`);
    })
  )
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS: 'common',
    fallbackLng: FALLBACK_LOCALE,
    supportedLngs: SUPPORTED_LOCALES,
    detection: {
      convertDetectedLanguage: (lng) => {
        return lng.indexOf('-') ? lng.split('-')[0] : lng;
      },
    },
  });

export default i18n;
