import { ApolloError } from '@apollo/client';

export const isApolloError = (error: Error): error is ApolloError => error.name === 'ApolloError';

function checkResponseCode(error: ApolloError, code: string) {
  return Boolean(error.graphQLErrors.find((err) => err.extensions?.['code']?.toString() === code));
}

export const isNotFoundError = (error: Error) =>
  isApolloError(error) &&
  (checkResponseCode(error, '404') ||
    error.message.startsWith('invalid input syntax for type uuid:'));

export const isForbiddenError = (error: Error) =>
  isApolloError(error) && checkResponseCode(error, '403');
