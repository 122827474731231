import { createRouter, RouterProvider } from '@tanstack/react-router';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import i18n from '@repo/i18n';
import { IdentifyError } from '@repo/ui/components/errors/identify-error.tsx';
import { NotFound } from '@repo/ui/components/errors/not-found.tsx';
import '@repo/ui/styles.css';

import { routeTree } from './routeTree.gen';

export interface AppConfig {
  clerkKey: string;
  coachUrl: string;
}

declare global {
  interface Window {
    _appConfig: AppConfig;
  }
}

const router = createRouter({
  routeTree,
  context: { i18n, appConfig: undefined as unknown as AppConfig },
  defaultNotFoundComponent: NotFound,
  defaultErrorComponent: IdentifyError,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

async function fetchAppConfig(): Promise<AppConfig> {
  if (import.meta.env.DEV) {
    return {
      clerkKey: import.meta.env.VITE_CLERK_PUBLISHABLE_KEY,
      coachUrl: '',
    };
  }
  return fetch('/configs/config.json').then((res) => {
    if (!res.ok) {
      throw new Error();
    }
    return res.json();
  });
}

void (async function bootstrap() {
  const appConfig = await fetchAppConfig();
  window._appConfig = appConfig;

  createRoot(document.getElementById('root')!).render(
    <StrictMode>
      <RouterProvider router={router} context={{ i18n, appConfig }} />
    </StrictMode>
  );
})();
