import { isForbiddenError, isNotFoundError } from '@repo/ui/components/errors/error.ts';
import { Forbidden } from '@repo/ui/components/errors/forbidden.tsx';
import { GenericError } from '@repo/ui/components/errors/generic-error.tsx';
import { NotFound } from '@repo/ui/components/errors/not-found.tsx';

export const IdentifyError = ({ error }: { error: Error }) => {
  console.log(error.message);

  if (isNotFoundError(error)) {
    return <NotFound />;
  }
  if (isForbiddenError(error)) {
    return <Forbidden />;
  }

  return <GenericError />;
};
