import { ClerkProvider } from '@clerk/clerk-react';
import { enGB } from '@clerk/localizations/en-GB';
import { itIT } from '@clerk/localizations/it-IT';
import { createFileRoute, Link, Outlet } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CookieConsentBanner } from '@repo/ui/components/cookie-consent-banner';

import { AppFooter } from '~/components/footer.tsx';
import { AppNavbar } from '~/components/navbar.tsx';

const itWithWaitlist: typeof itIT = {
  ...itIT,
  waitlist: {
    start: {
      actionLink: 'Accedi',
      actionText: 'Hai già un account?',
      formButton: 'Unisciti alla Waiting list',
      subtitle: 'Inserisci la tua email e ti faremo sapere quando saremo pronti',
      title: 'Unisciti alla Waiting list',
    },
    success: {
      message: 'Sarai reindirizzato a breve...',
      subtitle: 'Ti ricontatteremo quando saremo pronti',
      title: 'Grazie per esserti unito!',
    },
  },
};

export const Route = createFileRoute('/$pathLocale')({
  component: RouteComponent,
  beforeLoad: (ctx) => {
    ctx.context.i18n.changeLanguage(ctx.params.pathLocale);
  },
});

function RouteComponent() {
  const context = Route.useRouteContext();
  const { pathLocale } = Route.useParams();
  const { t } = useTranslation(['common']);

  const localization = useMemo(() => {
    switch (pathLocale) {
      case 'it':
        return itWithWaitlist;
      default:
        return enGB;
    }
  }, [pathLocale]);

  return (
    <ClerkProvider
      publishableKey={context.appConfig.clerkKey}
      appearance={{
        variables: {
          colorPrimary: 'hsl(359 76% 47%)',
        },
      }}
      localization={localization}
    >
      <div className="h-full">
        <AppNavbar />
        <main className="min-h-screen">
          <Outlet />
        </main>
        <AppFooter />
        <CookieConsentBanner
          cookiePolicyLink={
            <Link
              className="text-sm underline"
              to="/$pathLocale/cookie-policy"
              params={{ pathLocale }}
            >
              {t('common:privacy-policy.cookie-policy')}
            </Link>
          }
          privacyPolicyLink={
            <Link
              className="text-sm underline"
              to="/$pathLocale/privacy-policy"
              params={{ pathLocale }}
            >
              {t('common:privacy-policy.privacy-policy-website')}
            </Link>
          }
        />
      </div>
    </ClerkProvider>
  );
}
