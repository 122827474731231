import { BanIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const Forbidden = () => {
  const { t } = useTranslation(['common']);

  return (
    <div className="container mx-auto max-w-md p-8 flex flex-col justify-center h-screen gap-6 items-center">
      <BanIcon className="size-24 text-muted-foreground" />
      <div className=" flex items-center gap-2 text-2xl text-muted-foreground text-center">
        <span>{t('forbidden')}</span>
      </div>
    </div>
  );
};
