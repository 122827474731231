import { ArrowLeftIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const NotFound = () => {
  const { t } = useTranslation(['common']);

  return (
    <div className="container mx-auto max-w-sm p-8 flex flex-col justify-center h-screen gap-3">
      <h1 className="text-6xl">404</h1>
      <p className="text-3xl">{t('page-not-found')}</p>
      <button
        className="text-xl flex items-center gap-1 btn w-fit"
        onClick={() => {
          if (typeof window !== 'undefined') window.history.back();
        }}
        type="button"
      >
        <ArrowLeftIcon className="size-6" />
        {t('go-back')}
      </button>
    </div>
  );
};
