import { createFileRoute } from '@tanstack/react-router';

import { owner, publishDate } from '@repo/privacy';
import { components } from '@repo/privacy/components/components.tsx';
import PrivacyPolicy from '@repo/privacy/privacy-policy.mdx';

export const Route = createFileRoute('/$pathLocale/_privacy/privacy-policy')({
  component: RouteComponent,
});

const formatter = new Intl.DateTimeFormat('it');

function RouteComponent() {
  return (
    <PrivacyPolicy
      owner={owner}
      formattedPublishDate={formatter.format(publishDate)}
      components={components}
    />
  );
}
