import { createFileRoute, Outlet } from '@tanstack/react-router';

import { PrivacyLayout } from '@repo/privacy/components/privacy-layout.tsx';

export const Route = createFileRoute('/$pathLocale/_privacy')({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <PrivacyLayout hideNavbar>
      <Outlet />
    </PrivacyLayout>
  );
}
