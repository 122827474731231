import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { i18n } from 'i18next';

import type { AppConfig } from '~/main.tsx';

export interface AppContext {
  i18n: i18n;
  appConfig: AppConfig;
}

export const Route = createRootRouteWithContext<AppContext>()({
  component: RootComponent,
});

function RootComponent() {
  return <Outlet />;
}
