import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import previewUrl from '~/assets/preview.png?url';
import { CtaButton } from '~/components/cta-button.tsx';

export const Route = createFileRoute('/$pathLocale/')({
  component: RouteComponent,
});

function RouteComponent() {
  const { t } = useTranslation(['landing', 'common']);

  return (
    <div className="container mx-auto flex flex-col md:flex-row items-center min-h-[600px]">
      <div className="w-full md:w-1/2 text-center md:text-left">
        <div className="space-y-4 pt-4 md:p-4">
          <h2 className="font-semibold text-3xl md:text-4xl">{t('landing:hero-title')}</h2>
          <p className="text-muted-foreground text-lg">{t('landing:hero-description')}</p>
          <CtaButton />
          <p className="text-muted-foreground text-xs">{t('landing:hero-cta-description')}</p>
        </div>
      </div>
      <div className="w-full md:w-1/2 flex items-center justify-center">
        <div className="pt-8 px-2 md:p-4">
          <img
            src={previewUrl}
            alt="Preview"
            className="align-middle h-auto w-[948px] rounded-lg shadow-md"
          />
        </div>
      </div>
    </div>
  );
}
