/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as PathLocaleImport } from './routes/$pathLocale'
import { Route as IndexImport } from './routes/index'
import { Route as PathLocaleIndexImport } from './routes/$pathLocale/index'
import { Route as PathLocalePrivacyImport } from './routes/$pathLocale/_privacy'
import { Route as PathLocalePrivacyPrivacyPolicyImport } from './routes/$pathLocale/_privacy/privacy-policy'
import { Route as PathLocalePrivacyCookiePolicyImport } from './routes/$pathLocale/_privacy/cookie-policy'

// Create/Update Routes

const PathLocaleRoute = PathLocaleImport.update({
  id: '/$pathLocale',
  path: '/$pathLocale',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const PathLocaleIndexRoute = PathLocaleIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PathLocaleRoute,
} as any)

const PathLocalePrivacyRoute = PathLocalePrivacyImport.update({
  id: '/_privacy',
  getParentRoute: () => PathLocaleRoute,
} as any)

const PathLocalePrivacyPrivacyPolicyRoute =
  PathLocalePrivacyPrivacyPolicyImport.update({
    id: '/privacy-policy',
    path: '/privacy-policy',
    getParentRoute: () => PathLocalePrivacyRoute,
  } as any)

const PathLocalePrivacyCookiePolicyRoute =
  PathLocalePrivacyCookiePolicyImport.update({
    id: '/cookie-policy',
    path: '/cookie-policy',
    getParentRoute: () => PathLocalePrivacyRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/$pathLocale': {
      id: '/$pathLocale'
      path: '/$pathLocale'
      fullPath: '/$pathLocale'
      preLoaderRoute: typeof PathLocaleImport
      parentRoute: typeof rootRoute
    }
    '/$pathLocale/_privacy': {
      id: '/$pathLocale/_privacy'
      path: ''
      fullPath: '/$pathLocale'
      preLoaderRoute: typeof PathLocalePrivacyImport
      parentRoute: typeof PathLocaleImport
    }
    '/$pathLocale/': {
      id: '/$pathLocale/'
      path: '/'
      fullPath: '/$pathLocale/'
      preLoaderRoute: typeof PathLocaleIndexImport
      parentRoute: typeof PathLocaleImport
    }
    '/$pathLocale/_privacy/cookie-policy': {
      id: '/$pathLocale/_privacy/cookie-policy'
      path: '/cookie-policy'
      fullPath: '/$pathLocale/cookie-policy'
      preLoaderRoute: typeof PathLocalePrivacyCookiePolicyImport
      parentRoute: typeof PathLocalePrivacyImport
    }
    '/$pathLocale/_privacy/privacy-policy': {
      id: '/$pathLocale/_privacy/privacy-policy'
      path: '/privacy-policy'
      fullPath: '/$pathLocale/privacy-policy'
      preLoaderRoute: typeof PathLocalePrivacyPrivacyPolicyImport
      parentRoute: typeof PathLocalePrivacyImport
    }
  }
}

// Create and export the route tree

interface PathLocalePrivacyRouteChildren {
  PathLocalePrivacyCookiePolicyRoute: typeof PathLocalePrivacyCookiePolicyRoute
  PathLocalePrivacyPrivacyPolicyRoute: typeof PathLocalePrivacyPrivacyPolicyRoute
}

const PathLocalePrivacyRouteChildren: PathLocalePrivacyRouteChildren = {
  PathLocalePrivacyCookiePolicyRoute: PathLocalePrivacyCookiePolicyRoute,
  PathLocalePrivacyPrivacyPolicyRoute: PathLocalePrivacyPrivacyPolicyRoute,
}

const PathLocalePrivacyRouteWithChildren =
  PathLocalePrivacyRoute._addFileChildren(PathLocalePrivacyRouteChildren)

interface PathLocaleRouteChildren {
  PathLocalePrivacyRoute: typeof PathLocalePrivacyRouteWithChildren
  PathLocaleIndexRoute: typeof PathLocaleIndexRoute
}

const PathLocaleRouteChildren: PathLocaleRouteChildren = {
  PathLocalePrivacyRoute: PathLocalePrivacyRouteWithChildren,
  PathLocaleIndexRoute: PathLocaleIndexRoute,
}

const PathLocaleRouteWithChildren = PathLocaleRoute._addFileChildren(
  PathLocaleRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/$pathLocale': typeof PathLocalePrivacyRouteWithChildren
  '/$pathLocale/': typeof PathLocaleIndexRoute
  '/$pathLocale/cookie-policy': typeof PathLocalePrivacyCookiePolicyRoute
  '/$pathLocale/privacy-policy': typeof PathLocalePrivacyPrivacyPolicyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/$pathLocale': typeof PathLocaleIndexRoute
  '/$pathLocale/cookie-policy': typeof PathLocalePrivacyCookiePolicyRoute
  '/$pathLocale/privacy-policy': typeof PathLocalePrivacyPrivacyPolicyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/$pathLocale': typeof PathLocaleRouteWithChildren
  '/$pathLocale/_privacy': typeof PathLocalePrivacyRouteWithChildren
  '/$pathLocale/': typeof PathLocaleIndexRoute
  '/$pathLocale/_privacy/cookie-policy': typeof PathLocalePrivacyCookiePolicyRoute
  '/$pathLocale/_privacy/privacy-policy': typeof PathLocalePrivacyPrivacyPolicyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/$pathLocale'
    | '/$pathLocale/'
    | '/$pathLocale/cookie-policy'
    | '/$pathLocale/privacy-policy'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/$pathLocale'
    | '/$pathLocale/cookie-policy'
    | '/$pathLocale/privacy-policy'
  id:
    | '__root__'
    | '/'
    | '/$pathLocale'
    | '/$pathLocale/_privacy'
    | '/$pathLocale/'
    | '/$pathLocale/_privacy/cookie-policy'
    | '/$pathLocale/_privacy/privacy-policy'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  PathLocaleRoute: typeof PathLocaleRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  PathLocaleRoute: PathLocaleRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/$pathLocale"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/$pathLocale": {
      "filePath": "$pathLocale.tsx",
      "children": [
        "/$pathLocale/_privacy",
        "/$pathLocale/"
      ]
    },
    "/$pathLocale/_privacy": {
      "filePath": "$pathLocale/_privacy.tsx",
      "parent": "/$pathLocale",
      "children": [
        "/$pathLocale/_privacy/cookie-policy",
        "/$pathLocale/_privacy/privacy-policy"
      ]
    },
    "/$pathLocale/": {
      "filePath": "$pathLocale/index.tsx",
      "parent": "/$pathLocale"
    },
    "/$pathLocale/_privacy/cookie-policy": {
      "filePath": "$pathLocale/_privacy/cookie-policy.tsx",
      "parent": "/$pathLocale/_privacy"
    },
    "/$pathLocale/_privacy/privacy-policy": {
      "filePath": "$pathLocale/_privacy/privacy-policy.tsx",
      "parent": "/$pathLocale/_privacy"
    }
  }
}
ROUTE_MANIFEST_END */
