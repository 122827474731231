import { createFileRoute } from '@tanstack/react-router';

import { owner, publishDate } from '@repo/privacy';
import { components } from '@repo/privacy/components/components.tsx';
import CookiePolicy from '@repo/privacy/cookie-policy.mdx';

export const Route = createFileRoute('/$pathLocale/_privacy/cookie-policy')({
  component: RouteComponent,
});

const formatter = new Intl.DateTimeFormat('it');

function RouteComponent() {
  return (
    <CookiePolicy
      owner={owner}
      formattedPublishDate={formatter.format(publishDate)}
      components={components}
    />
  );
}
